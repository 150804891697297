@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@tailwind base;
/* apply here tailwind properties you want to attribute to each element */
@layer base {
  @font-face {
    font-family: 'Poppins';
    font-weight: 400;
    src: url('./fonts/poppins/Poppins-Regular.ttf');
  },
  @font-face {
    font-family: 'Poppins';
    font-weight: 500;
    src: url('./fonts/poppins/Poppins-Medium.ttf');
  },
  @font-face {
    font-family: 'Poppins';
    font-weight: 700;
    src: url('./fonts/poppins/Poppins-Bold.ttf');
  },
  @font-face {
    font-family: 'Cervanttis';
    font-weight: 300;
    src: url('./fonts/cervanttis/Cervanttis.ttf');
  }
  body {
    @apply text-black font-sans;
  }
  h1 {
    @apply text-xl;
  }
  h2 {
    @apply text-xl;
  }
  p {
    @apply text-xs font-normal;
  }
/* phone number input in signup page step 3 */
  .PhoneInputInput {
    border: solid 1px #AFAFAF;
    border-radius: 0.25rem;
  }
}

@tailwind components;
@tailwind utilities;
